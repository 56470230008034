@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-dark: #13547a; /* Light grayish white */
  --primary-light: #80d0c7; /* Pure white */
  --primary-dark1: #f2f2f2; /* Light grayish white */
  --primary-light1: #ffffff; /* Pure white */
}

/* global styles */
@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply font-[Poppins];
  }

  li {
    @apply p-4 text-sm;
  }

  button {
    @apply px-4 py-2 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white rounded-md font-semibold;
  }

  nav {
    @apply bg-gradient-to-r from-[var(--primary-dark1)] to-[var(--primary-light1)];
  }

  h2 {
    @apply text-xl font-bold;
  }
}

/* Class to hide the scrollbar but still allow scrolling */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px; /* WebKit browsers */
}

/* Customize the size and padding of SweetAlert */
.small-swal {
  padding: 1em !important; /* Less padding */
}

.small-swal .swal2-title {
  font-size: 1.2em; /* Smaller font size */
}

.small-swal .swal2-content {
  font-size: 1em; /* Adjust content size */
}

.container {
  width: 100%; /* Ensures full-width content for A4 */
}

/* Avoid page breaks inside these elements */
.no-page-break {
  page-break-inside: avoid;
}

/* Force a page break before these elements */
.page-break {
  page-break-before: always;
}

/* Force a page break after these elements */
.page-break-after {
  page-break-after: always;
}

table {
  page-break-inside: auto;
  page-break-before: avoid;
}

tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

.hide-scrollbar {
  overflow: auto; /* Enables scrolling */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
}
